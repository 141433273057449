import React from 'react'
import '../../App.css';
import Contact from '../contact/Contact'
import Footer from '../foots/Footer'

function ContactPage() {
    return (
        <>
        <Contact/>
        <Footer />
    </>
    )
}

export default ContactPage
