import React , {useState} from 'react';
import '../../App.css';
import Slideshow from '../slide/Slideshow';
import Footer from '../foots/Footer'


function Gallery() {


  return (
      
    <>
        <Slideshow/>
        <Footer />
    </>
  );
}

export default Gallery;